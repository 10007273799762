import React from 'react';
import EnergyTag from '../EnergyTag';
import EnergyLabelList from './EnergyLabelList';
import { useEnergyClass } from './hooks';
var EnergyClass = function (_a) {
    var energyClass = _a.class, href = _a.href, shouldHandleEnergyHash = _a.shouldHandleEnergyHash, labelHeight = _a.labelHeight, _b = _a.labelUrls, labelUrls = _b === void 0 ? [] : _b, _c = _a.isDrawerToggleEnabled, isDrawerToggleEnabled = _c === void 0 ? true : _c;
    var openDrawer = useEnergyClass({
        content: React.createElement(EnergyLabelList, { urls: labelUrls }),
        labelUrls: labelUrls,
        shouldHandleEnergyHash: shouldHandleEnergyHash,
    });
    var shouldOpenDrawer = isDrawerToggleEnabled && !href && labelUrls.length > 0;
    return (React.createElement(EnergyTag, { href: href, onClick: shouldOpenDrawer ? openDrawer : null, energyClass: energyClass, labelHeight: labelHeight }));
};
export default EnergyClass;
