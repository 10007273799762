var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import styled from '@emotion/styled';
import { width, flex, alignItems } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import EnergyLabel from '../EnergyLabel';
import { Flex } from '../Flex';
var Container = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
Container.defaultProps = __assign(__assign({}, Flex.defaultProps), { as: 'ul', m: 'sp_0', p: 'sp_0', flexDirection: ['column', null, null, 'row'], flexWrap: 'wrap' });
var Item = styled(EnergyLabel)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), width, flex, alignItems);
Item.defaultProps = __assign(__assign({}, EnergyLabel.defaultProps), { as: 'li', alignItems: 'flex-start' });
var EnergyLabelList = function (_a) {
    var urls = _a.urls;
    var t = useLocalization().t;
    var isSingleItem = urls.length === 1;
    var styleProps = __assign({ width: [null, null, null, isSingleItem ? 1 : 1 / 5] }, (!isSingleItem && { flex: '0 50%' }));
    return (React.createElement(Container, null, urls.map(function (url) { return (React.createElement(Item, __assign({ key: url, url: url, alt: t('h24_energy_label') }, styleProps))); })));
};
export default memo(EnergyLabelList);
var templateObject_1, templateObject_2;
