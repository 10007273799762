export var EnergyClassCode;
(function (EnergyClassCode) {
    EnergyClassCode["APlusPlus"] = "A++";
    EnergyClassCode["APlus"] = "A+";
    EnergyClassCode["A"] = "A";
    EnergyClassCode["B"] = "B";
    EnergyClassCode["C"] = "C";
    EnergyClassCode["D"] = "D";
    EnergyClassCode["E"] = "E";
    EnergyClassCode["F"] = "F";
    EnergyClassCode["G"] = "G";
})(EnergyClassCode || (EnergyClassCode = {}));
export var EnergyClassColor;
(function (EnergyClassColor) {
    EnergyClassColor["A"] = "#00a54f";
    EnergyClassColor["B"] = "#4cb848";
    EnergyClassColor["C"] = "#bed630";
    EnergyClassColor["D"] = "#fff101";
    EnergyClassColor["E"] = "#fcb814";
    EnergyClassColor["F"] = "#f36e21";
    EnergyClassColor["G"] = "#ee1d23";
})(EnergyClassColor || (EnergyClassColor = {}));
