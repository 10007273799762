var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { justifyContent, display, maxWidth, space } from 'styled-system';
var MAX_WIDTH_S = '300px';
var MAX_WIDTH_M = '320px';
var MAX_WIDTH_XL = '290px';
var LabelContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), display, justifyContent, space);
LabelContainer.defaultProps = {
    display: 'flex',
    justifyContent: 'center',
    py: ['sp_0', 'sp_24'],
};
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), maxWidth);
Image.defaultProps = {
    maxWidth: [MAX_WIDTH_S, MAX_WIDTH_M, null, MAX_WIDTH_XL],
};
var EnergyLabel = function (_a) {
    var url = _a.url, alt = _a.alt, restProps = __rest(_a, ["url", "alt"]);
    return (React.createElement(LabelContainer, __assign({ "data-testid": "energy-label" }, restProps),
        React.createElement(Image, { alt: alt, src: url })));
};
export default EnergyLabel;
var templateObject_1, templateObject_2;
