import { energyClassColors } from '../constants/energyClass';
import { EnergyClassCode } from '../enums/EnergyClass';
var supportedEnergyClasses = [
    EnergyClassCode.A,
    EnergyClassCode.B,
    EnergyClassCode.C,
    EnergyClassCode.D,
    EnergyClassCode.E,
    EnergyClassCode.F,
    EnergyClassCode.G,
];
var isSupportedEnergyClassCode = function (code) {
    return supportedEnergyClasses.includes(code);
};
export var getEnergyClassMeta = function (code) {
    if (!isSupportedEnergyClassCode(code)) {
        code = EnergyClassCode.G;
    }
    return [code, energyClassColors[code]];
};
