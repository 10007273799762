import { useLocalization } from '../providers/LocaleProvider';
import { toOldGQLLocale, toGQLLocale } from '../gql/utils/gql-locale';
/** @deprecated Use `useGQLLocale` */
export var useOldGqlLocale = function () {
    var locale = useLocalization().locale;
    return toOldGQLLocale(locale);
};
export var useGQLLocale = function () {
    var locale = useLocalization().locale;
    return toGQLLocale(locale);
};
