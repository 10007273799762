var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from '@emotion/styled';
import { alignItems, alignSelf, display, flexWrap, fontSize, fontWeight, lineHeight, space, themeGet, } from 'styled-system';
import { Text } from '../Text';
var TextColor;
(function (TextColor) {
    TextColor["Default"] = "colors.sys.neutral.text.active";
    TextColor["Sale"] = "colors.sys.primary.text.default";
})(TextColor || (TextColor = {}));
var PriceContainer = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), alignItems, flexWrap, display);
PriceContainer.defaultProps = {
    fontSize: ['fs_20', 'fs_24', null, 'fs_28'],
    lineHeight: ['lh_24', 'lh_28', null, 'lh_32'],
    fontWeight: 'bold',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    display: 'flex',
};
var PriceContainerLarge = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), alignItems, flexWrap, display);
PriceContainerLarge.defaultProps = {
    alignItems: 'center',
    flexWrap: 'wrap',
    display: 'flex',
};
var Price = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  color: ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  color: ", ";\n"])), display, alignSelf, space, function (_a) {
    var isOnSale = _a.isOnSale;
    return themeGet(isOnSale ? TextColor.Sale : TextColor.Default);
});
Price.defaultProps = {
    display: 'inline-block',
    alignSelf: 'center',
    pr: ['sp_6', null, null, 'sp_8'],
};
var PriceLarge = styled(Price)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), fontSize, fontWeight, lineHeight);
PriceLarge.defaultProps = __assign(__assign({}, Price.defaultProps), { fontSize: ['fs_20', 'fs_24', 'fs_28', 'fs_32'], lineHeight: ['lh_28', 'lh_32', 'lh_36', 'lh_40'], fontWeight: 'bold', ml: 'sp_0' });
export { Price, PriceLarge, PriceContainer, PriceContainerLarge };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
