var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from '@emotion/styled';
import { background, backgroundColor, border, borderRight, borderWidth, color, fontFamily, fontSize, fontWeight, height, lineHeight, padding, position, space, width, } from 'styled-system';
import range from '../assets/icons/energy-tag-range.svg';
import { useLocalization } from '../providers/LocaleProvider';
import { focusOutlineCSSWithOffset } from '../theme/styles';
import { boxSizing, outline, textDecoration, textShadow } from '../theme/system-utilities';
import { getEnergyClassMeta } from '../utils/energyClass';
import { mapResponsiveValue } from '../utils/style';
import { Flex } from './Flex';
var ENERGY_TAG_RANGE_SVG_RATIO = 26 / 10;
var getArrowSize = function (labelHeight) {
    return labelHeight.replace(/\d+/, function (number) { return "".concat(parseInt(number, 10) / 2); });
};
var getArrowBorderWidth = function (labelHeight) {
    return labelHeight.replace(/\d+/, function (number) { return "".concat(parseInt(number, 10) / 2 + 1); });
};
var getEnergyTagRangeSVGHeight = function (labelHeight) {
    return labelHeight.replace(/\d+/, function (number) {
        return (parseInt(number, 10) / ENERGY_TAG_RANGE_SVG_RATIO).toFixed(2);
    });
};
var setArrowSize = function (labelHeight) { return function (props) { return [
    borderWidth(__assign(__assign({}, props), { borderWidth: mapResponsiveValue(labelHeight, getArrowSize) })),
]; }; };
var setArrowBorderSize = function (labelHeight) { return function (props) { return [
    borderWidth(__assign(__assign({}, props), { borderWidth: mapResponsiveValue(labelHeight, getArrowBorderWidth) })),
]; }; };
var Container = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n\n  * {\n    pointer-events: none;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n\n  * {\n    pointer-events: none;\n  }\n"])), padding, outline, border, fontSize, background, lineHeight, textDecoration, focusOutlineCSSWithOffset);
var containerStyleProps = {
    padding: 0,
    border: 'none',
    outline: 'none',
    background: 'none',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    textDecoration: 'unset',
};
var ContainerWithPointer = styled(Container)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var Energy = styled(Flex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  overflow: visible;\n\n  &::after {\n    content: ' ';\n    position: absolute;\n    left: 100%;\n    height: 0;\n    width: 0;\n    border: solid transparent;\n    border-left-color: ", ";\n    ", ";\n  }\n\n  &::before {\n    content: ' ';\n    position: absolute;\n    left: 100%;\n    height: 0;\n    width: 0;\n    top: -1px;\n    border: solid transparent;\n    border-left-color: black;\n    ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  overflow: visible;\n\n  &::after {\n    content: ' ';\n    position: absolute;\n    left: 100%;\n    height: 0;\n    width: 0;\n    border: solid transparent;\n    border-left-color: ", ";\n    ", ";\n  }\n\n  &::before {\n    content: ' ';\n    position: absolute;\n    left: 100%;\n    height: 0;\n    width: 0;\n    top: -1px;\n    border: solid transparent;\n    border-left-color: black;\n    ", ";\n  }\n"])), height, border, borderRight, color, position, boxSizing, fontWeight, lineHeight, function (props) { return props.bg; }, function (_a) {
    var labelHeight = _a.height;
    return setArrowSize(labelHeight);
}, function (_a) {
    var labelHeight = _a.height;
    return setArrowBorderSize(labelHeight);
});
Energy.defaultProps = {
    boxSizing: 'content-box',
    position: 'relative',
    textAlign: 'left',
    p: 'sp_0',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    alignItems: 'stretch',
    fontWeight: '700',
    border: '1px solid black',
    borderRight: 'none',
};
var EnergyCode = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, color, fontSize, fontFamily, lineHeight, textShadow);
EnergyCode.defaultProps = {
    px: 1,
    color: 'sys.neutral.background.default',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontFamily: 'Calibri-Bold, Calibri, Arial',
    fontWeight: '700',
    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
};
var Img = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), height, width, backgroundColor, fontSize);
Img.defaultProps = {
    fontSize: '50%',
    backgroundColor: 'sys.neutral.background.default',
};
var EnergyTag = function (_a) {
    var energyClass = _a.energyClass, href = _a.href, onClick = _a.onClick, _b = _a.labelHeight, labelHeight = _b === void 0 ? ['16px', '18px', null, '20px'] : _b;
    var t = useLocalization().t;
    var props = href
        ? { href: href, as: 'a' }
        : { as: onClick ? 'button' : 'span' };
    var _c = __read(getEnergyClassMeta(energyClass), 2), energyClassCode = _c[0], energyClassColor = _c[1];
    var ContainerComponent = onClick || href ? ContainerWithPointer : Container;
    var energyTagRangeSVGHeight = mapResponsiveValue(labelHeight, getEnergyTagRangeSVGHeight);
    return (React.createElement("object", { title: t('h24_energy_label') },
        React.createElement(ContainerComponent, __assign({}, props, containerStyleProps, { "data-testid": "article-energy", onClick: onClick, "data-value": energyClassCode, "aria-label": t('h24_energy_label') }),
            React.createElement(Energy, { height: labelHeight, lineHeight: labelHeight, mr: mapResponsiveValue(labelHeight, getArrowBorderWidth), bg: energyClassColor },
                React.createElement(Img, { loading: "lazy", src: range, height: labelHeight, width: energyTagRangeSVGHeight, alt: "G\u2191A" }),
                React.createElement(EnergyCode, null, energyClassCode)))));
};
export default EnergyTag;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
