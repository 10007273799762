import { GQLLocale } from 'graphql-ts-types';
import { Locale } from '../../enums/Locale';
/** @deprecated Use `toGqlLocale` */
export var toOldGQLLocale = function (locale) {
    return locale.slice(0, 5);
};
export var toGQLLocale = function (locale) {
    var _a;
    return ((_a = {},
        _a[Locale.De_At] = GQLLocale.DeAt,
        _a[Locale.De_Ch] = GQLLocale.DeCh,
        _a[Locale.De_De_H24] = GQLLocale.DeDe,
        _a[Locale.De_De_Ffh] = GQLLocale.DeFfh,
        _a[Locale.Fr_Be] = GQLLocale.FrBe,
        _a[Locale.Fr_Ch] = GQLLocale.FrCh,
        _a[Locale.Fr_Fr] = GQLLocale.FrFr,
        _a[Locale.It_It] = GQLLocale.ItIt,
        _a[Locale.Nl_Be] = GQLLocale.NlBe,
        _a[Locale.Nl_Nl] = GQLLocale.NlNl,
        _a)[locale]);
};
