var _a;
import { EnergyClassCode, EnergyClassColor } from '../enums/EnergyClass';
export var energyClassColors = (_a = {},
    _a[EnergyClassCode.A] = EnergyClassColor.A,
    _a[EnergyClassCode.B] = EnergyClassColor.B,
    _a[EnergyClassCode.C] = EnergyClassColor.C,
    _a[EnergyClassCode.D] = EnergyClassColor.D,
    _a[EnergyClassCode.E] = EnergyClassColor.E,
    _a[EnergyClassCode.F] = EnergyClassColor.F,
    _a[EnergyClassCode.G] = EnergyClassColor.G,
    _a);
export var ENERGY_EFFICIENCY_HASH = '#energy-efficiency';
